import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['item', 'template', 'list', 'position']

  connect() {
    this.indexCounter = this.itemTargets.length
    this.updatePositions()
    this.updateMoveButtons()
  }

  add(event) {
    event.preventDefault()
    const column = event.currentTarget.dataset.column
    const thisTemplateId = this.templateTargets[0].id
    const templatePrefix = thisTemplateId.split('_').slice(0, -1).join('_')
    const templateId = `${templatePrefix}_${column}`
    const template = document.getElementById(templateId)
    const content = template.content.cloneNode(true)
    this.updateCloneIndices(content)

    const list = this.listTargets.find((list) => list.dataset.column === column)
    list.appendChild(content)

    this.indexCounter++
    this.updatePositions()
    this.updateMoveButtons()
  }

  remove(event) {
    event.preventDefault()
    const item = event.target.closest(
      '[data-form--nested-array-columns-target="item"]'
    )
    const destroyField = item.querySelector('input[name$="[_destroy]"]')
    if (destroyField) {
      destroyField.value = '1'
      item.style.display = 'none'
    } else {
      item.remove()
    }
    this.updatePositions()
    this.updateMoveButtons()
  }

  moveUp(event) {
    event.preventDefault()
    const item = event.target.closest(
      '[data-form--nested-array-columns-target="item"]'
    )
    const column = item.dataset.column
    const previousItem = this.getPreviousItemInColumn(item, column)
    if (previousItem) {
      this.animateSwap(item, previousItem, true)
    }
  }

  moveDown(event) {
    event.preventDefault()
    const item = event.target.closest(
      '[data-form--nested-array-columns-target="item"]'
    )
    const column = item.dataset.column
    const nextItem = this.getNextItemInColumn(item, column)
    if (nextItem) {
      this.animateSwap(item, nextItem, false)
    }
  }

  getPreviousItemInColumn(item, column) {
    let previous = item.previousElementSibling
    while (previous) {
      if (
        previous.dataset.column === column &&
        previous.style.display !== 'none'
      ) {
        return previous
      }
      previous = previous.previousElementSibling
    }
    return null
  }

  getNextItemInColumn(item, column) {
    let next = item.nextElementSibling
    while (next) {
      if (next.dataset.column === column && next.style.display !== 'none') {
        return next
      }
      next = next.nextElementSibling
    }
    return null
  }

  animateSwap(item1, item2, isMovingUp) {
    const height1 = item1.offsetHeight
    const height2 = item2.offsetHeight
    const move1 = isMovingUp ? -height2 : height2
    const move2 = isMovingUp ? height1 : -height1

    item1.style.transform = `translateY(0)`
    item2.style.transform = `translateY(0)`
    void item1.offsetWidth

    item1.style.transition = `transform 300ms ease-in-out`
    item2.style.transition = `transform 300ms ease-in-out`

    item1.style.transform = `translateY(${move1}px)`
    item2.style.transform = `translateY(${move2}px)`

    setTimeout(() => {
      item1.style.transition = ''
      item2.style.transition = ''
      item1.style.transform = ''
      item2.style.transform = ''

      if (isMovingUp) {
        item1.parentNode.insertBefore(item1, item2)
      } else {
        item1.parentNode.insertBefore(item2, item1)
      }

      this.updatePositions()
      this.updateMoveButtons()
    }, 300)
  }

  updateCloneIndices(content) {
    content.querySelectorAll('input, select, textarea').forEach((el) => {
      ;['name', 'id'].forEach((attr) => {
        const value = el.getAttribute(attr)
        if (value) {
          el.setAttribute(attr, value.replace('NEW_RECORD', this.indexCounter))
        }
      })
    })
  }

  updatePositions() {
    const columns = [
      ...new Set(this.itemTargets.map((item) => item.dataset.column)),
    ]
    columns.forEach((column) => {
      const columnItems = this.itemTargets.filter(
        (item) =>
          item.dataset.column === column && item.style.display !== 'none'
      )
      columnItems.forEach((item, index) => {
        const positionField = item.querySelector(
          '[data-form--nested-array-columns-target="position"]'
        )
        if (positionField) {
          positionField.value = index + 1
        }
      })
    })
  }

  updateMoveButtons() {
    const columns = [
      ...new Set(this.itemTargets.map((item) => item.dataset.column)),
    ]
    columns.forEach((column) => {
      const columnItems = this.itemTargets.filter(
        (item) =>
          item.dataset.column === column && item.style.display !== 'none'
      )
      columnItems.forEach((item, index) => {
        const upButton = item.querySelector('[data-action$="moveUp"]')
        const downButton = item.querySelector('[data-action$="moveDown"]')

        if (upButton) upButton.style.display = index === 0 ? 'none' : ''
        if (downButton)
          downButton.style.display =
            index === columnItems.length - 1 ? 'none' : ''
      })
    })
  }
}
